import { styled } from 'styled-components';
import { Link as RemixLink } from '@remix-run/react';
import type { LinkProps } from '@remix-run/react';
import type { StyledTransient } from '@hn-ui/shared';

interface ILinkProps extends LinkProps {
  bold?: boolean;
}

type IContainerProps = StyledTransient<Required<ILinkProps>, 'bold'>;

const Container = styled(RemixLink)<IContainerProps>`
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  border: none;
  font-weight: ${({ $bold }) => ($bold ? 700 : 400)};
  outline: none;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.blue50};
  white-space: nowrap;
  gap: 8px;
  &:focus {
    color: ${({ theme }) => theme.colors.blue40};
  }
  cursor: pointer;
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: ${({ theme }) => theme.colors.blue40};
      && > svg > path {
        fill: ${({ theme }) => theme.colors.blue40};
      }
    }
  }
`;

const Link = ({
  bold = false,
  children,
  ...rest
}: React.PropsWithChildren<ILinkProps>) => {
  return (
    <Container $bold={bold} {...rest}>
      {children}
    </Container>
  );
};

export { Link };
